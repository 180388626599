.xcn-dashboard-sub-nav {
  height: 100%;
  position: fixed;
  z-index: 2;
  left: 0;
  text-align: left;
  top: 0;
  width: 13rem;
  transition: all 0.5s cubic-bezier(0.13, 0.88, 1, 1);
  padding-right: 2rem;
}
.xcn-dash-logo {
  height: 2rem;
}
.xcn-nav-selected {
  border-right: 5px solid white;
}

.xcn-sidebar-links {
  /* padding: 0.65rem 0rem; */
  opacity: 0.9;
  cursor: pointer;
  font-size: 13px;
  margin: 0.25rem 1rem 0.25rem 1rem;
  padding: 0.25rem 1rem 0.25rem 1rem;
  background-color: white;
  border-radius: 10px;
  font-weight: 700;
}

.xcn-sidebar-links-inactive {
  opacity: 0.9;
  cursor: pointer;
  font-size: 13px;
  margin: 0.25rem 1rem 0.25rem 1rem;
  padding:0.25rem 1rem 0.25rem 1rem;
  border-radius: 10px;
  font-weight: 700;
  color: #c1b8f1;
}

.xrg-nav-logout {
  margin-top: auto;
  /* margin-bottom: 1rem; */
}

.sidebar-element {
  color: #fff;
  opacity: 0.5;
  cursor: pointer;
  font-size: 10px;
}


.xrg-nav-bottom{
  margin-top:auto;
  margin-bottom: 1rem;
}