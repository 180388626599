@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');


.login-parent{
  height: 100vh;
  width: 100vw;
  background: rgb(118,130,231);
  background: linear-gradient(90deg, rgba(118,130,231,1) 0%, rgba(72,87,210,1) 50%, rgba(118,130,231,1) 100%);
}
.login-card{
  width: 400px;
}

.reg-card{
  border: none !important;
  background-color: white !important;
}

.App {
  min-height: 100vh;
  min-width: 100vw;
  font-family: 'Poppins', sans-serif;
}
.xcn-link-pointer{
  cursor: pointer;
}

.xcn-input{
  text-align: left;
}

.xcn-fw-100{
  font-weight: 100 !important;
}

.xcn-fw-200{
  font-weight: 200 !important;
}

.xcn-fw-300{
  font-weight: 300 !important;
}

.xcn-fw-400{
  font-weight: 400 !important;
}

.xcn-fw-500{
  font-weight: 500 !important;
}

.xcn-fw-600{
  font-weight: 600 !important;
}

.xcn-text-14{
  font-size: 0.9rem !important;
}

.xcn-text-12{
  font-size: 0.8rem !important;
}
.xcn-text-10{
  font-size: 0.7rem !important;
}

.xcn-text-9{
  font-size: 0.5rem !important;
}

.xcn-text-16{
  font-size: 0.95rem !important;
}

.xcn-text-18{
  font-size: 1.15rem !important;
}
.xcn-text-xl{
  font-size: 3rem !important;
}

.xcn-link-pointer{
  cursor: pointer;
}

.xcn-table-tr{
  height: 5rem;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.margin-left-6{
  margin-left: 6rem !important;
}

.box-shadow{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.xcn-search-bar{
  height: 2rem;
  width: 20rem;
  background-color: #F5F3FF;
  border: none;
  color: #5041bc;
  font-size: 14px;
  border-radius: 30px;
  padding-left:1rem;
}
::placeholder{
  color: #5041bc;
  opacity: 1;
}
:focus{
  outline: none;
}

.xcn-button{
  border-radius: 15px !important;
  padding: 0.4rem 0.75rem 0.4rem 0.75rem !important;
}

.xcn-table-row{
  border: 1px solid #dfdfdf;
  padding: 1rem;
  width: 100%;
  font-size: 12px;
  border-radius: 15px;
}

.xcn-input{
  border-radius: 12px !important;
  height:45px !important;
  border: none !important;
  background-color: #f8f6ff !important;
  font-size: 14px !important;
}

.xcn-input-label{
  font-size: 12px;
  font-weight: bold !important;
}