.xcn-loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:#655d9d7d;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}