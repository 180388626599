.xcn-dropzone {
    min-height: 20rem;
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 5px dashed #eeeeee;
    border-radius: 1rem;
    padding: 50px;
}
.xcn-dropzone-small{
    min-height: 10rem;
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 5px dashed #eeeeee;
    border-radius: 1rem;

}